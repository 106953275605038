import { FC } from 'react';
import clsx from 'clsx';
import { getServiceStationOpeningHours } from '../../../utils/getServiceStationOpeningHours';
import {
  ServiceStationDto,
  ServiceStationWithDetailWorkingScheduleDto,
} from '../../../api/generated/caready';

interface OwnProps {
  serviceStation: ServiceStationWithDetailWorkingScheduleDto | ServiceStationDto;
  className?: string;
}

export const ServiceStationWorkStatus: FC<OwnProps> = function ServiceStationWorkStatus(props) {
  const openingHour = getServiceStationOpeningHours(props.serviceStation);

  return (
    <p className={clsx('map-info', props.className)}>
      <span className={clsx(!props.serviceStation.openingHours.isOpen && 'closed')}>
        {props.serviceStation.openingHours.isOpen ? 'Открыто' : 'Закрыто'}
      </span>
      {openingHour && (
        <>
          <span className={'dot'} />
          <span>{openingHour}</span>
        </>
      )}
    </p>
  );
};
