import { FC } from 'react';
import { useMap } from '../../context/MapProvider';
import { getServiceStationAddress } from '../../../../../utils/getServiceStationAddress';
import { ServiceStationWorkStatus } from '../../../ServiceStationWorkStatus/ServiceStationWorkStatus';
import s from './ServiceStationList.module.scss';
import { ServiceStationDto } from '../../../../../api/generated/caready';

interface OwnProps {
  onClose?: (value?: ServiceStationDto) => void;
}

export const ServiceStationList: FC<OwnProps> = function ServiceStationList(props) {
  const { serviceStations, setSelectedServiceStation } = useMap();

  return (
    <>
      {!!serviceStations.length ? (
        <ul>
          {serviceStations.map(it => (
            <li
              key={it.id}
              className={s.item}
              onClick={() => {
                setSelectedServiceStation(it);
                props.onClose && props.onClose(it);
              }}
            >
              <h6 className={'h6'}>{getServiceStationAddress(it)}</h6>
              <ServiceStationWorkStatus serviceStation={it} className={'p3'} />
            </li>
          ))}
        </ul>
      ) : (
        <div className={s.notFound}>
          <p className={'h6'}>По вашему запросу ничего не найдено</p>
        </div>
      )}
    </>
  );
};
