import { ForwardedRef, forwardRef, useState } from 'react';
import { ServiceStationList } from '../../../components/servicesStationList/ServiceStationList';
import { MapSearchHeader } from '../../../components/mapSearchHeader/MapSearchHeader';
import s from './MapSearch.module.scss';
import { FiltersButton } from '../../../components/mapFilters/FiltersButton';
import { MapModal } from '../mapModal/MapModal';
import { FilterList } from '../../../components/filterList/FilterList';
import { useMap } from '../../../context/MapProvider';
import { ReactComponent as ListIcon } from '../../../../../../assets/svg/list.svg';
import { Search } from '../../../../Search/Search';

interface OwnProps {
  setIsShowSelectedServiceStation: (value: boolean) => void;
}

export const MapSearch = forwardRef(function MapSearch(
  props: OwnProps,
  ref: ForwardedRef<HTMLDivElement | null>
) {
  const [isServiceStationListOpen, setIsServiceStationListOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const { selectedCategories, setSelectedCategories, address, setAddress } = useMap();

  return (
    <div className={s.wrapper} ref={ref}>
      <MapSearchHeader />
      <div className={s.actions}>
        <Search clear={() => setAddress('')} isFilled={!!address}>
          <input
            placeholder={'Введите адрес или город'}
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </Search>
        <FiltersButton toggleFilter={() => setIsFiltersOpen(true)} />
        <button className={s.actionButton} onClick={() => setIsServiceStationListOpen(true)}>
          <ListIcon />
        </button>
      </div>
      <MapModal
        isOpen={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        title={'Фильтры'}
        header={
          !!selectedCategories.length && (
            <button className={s.reset} onClick={() => setSelectedCategories([])}>
              Сбросить
            </button>
          )
        }
      >
        <FilterList />
      </MapModal>
      <MapModal
        isOpen={isServiceStationListOpen}
        onClose={() => setIsServiceStationListOpen(false)}
      >
        <ServiceStationList onClose={value => props.setIsShowSelectedServiceStation(!!value)} />
      </MapModal>
    </div>
  );
});
