import React, { useEffect, useRef, useState } from 'react';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { Clusterer, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps';
import { DefaultCity, useGeo } from '../../../../context/GeoProvider';
import { useMap } from '../context/MapProvider';
import { MapSearch } from './components/mapSearch/MapSearch';
import { MapControls } from '../components/mapControls/MapControls';
import mapStyles from '../map.module.scss';
import Pin from 'assets/img/map/pin.svg';
import s from './MobileMap.module.scss';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { Balloon } from './components/balloon/Balloon';
import { MapModal } from './components/mapModal/MapModal';
import { SelectedServiceStation } from '../components/selectedServiceStation/SelectedServiceStation';
import { useMatch } from '../../../../hooks/useMatch';
import breakPoints from '../../../../styles/breakpoints.module.scss';

const defaultOptions = {
  iconLayout: 'default#imageWithContent',
  iconImageSize: [32, 32],
  iconImageHref: Pin,
  iconImageOffset: [-16, -32],
};

const balloonId = 'balloon';
const controlsId = 'controls';

export const MobileMap = function MobileMap() {
  const { bounds, serviceStation } = useGeo();
  const { serviceStationsBySlugs, selectedServiceStation, setSelectedServiceStation } = useMap();
  const { isMatch } = useMatch(breakPoints.bp576);

  const [isBalloonShow, setIsBalloonShow] = useState(true);
  const [isBalloonOpen, setIsBalloonOpen] = useState(false);

  const balloonElement = document.getElementById(balloonId);
  const controlsElement = document.getElementById(controlsId);

  const [center, setCenter] = useState<number[] | undefined>();

  const searchRef = useRef<HTMLDivElement | null>(null);

  const [ymaps, setYmaps] = useState<YMapsApi | undefined>();
  const [zoom, setZoom] = useState<number>(9);
  const mapRef = useRef<ymaps.Map | undefined>(undefined);

  const [isShowSelectedServiceStation, setIsShowSelectedServiceStation] = useState<boolean>(false);

  useEffect(() => {
    if (!serviceStation) {
      setCenter(undefined);
      setZoom(9);
    }

    setIsBalloonOpen(false);
    setIsBalloonShow(true);
    setSelectedServiceStation(serviceStation);
  }, [serviceStation]);

  useEffect(() => {
    if (!selectedServiceStation) {
      return;
    }

    const { latitude, longitude } = selectedServiceStation.fullAddress.coordinates;

    setZoom(12);
    setCenter([latitude, longitude]);
  }, [selectedServiceStation]);

  useEffect(() => {
    const events = mapRef.current?.geoObjects.events;

    function onCloseBalloon() {
      setIsBalloonOpen(false);
      setIsBalloonShow(false);
    }

    events?.add('balloonclose', onCloseBalloon);

    return () => {
      events?.remove('balloonclose', onCloseBalloon);
    };
  }, [ymaps]);

  return (
    <div className={`${mapStyles.wrapper} ${s.wrapper}`}>
      <MapSearch
        ref={searchRef}
        setIsShowSelectedServiceStation={setIsShowSelectedServiceStation}
      />
      <MapModal
        isOpen={isShowSelectedServiceStation && !!selectedServiceStation}
        onClose={() => {
          setIsShowSelectedServiceStation(false);
          setSelectedServiceStation(
            selectedServiceStation?.id === serviceStation?.id ? serviceStation : null
          );
        }}
      >
        {selectedServiceStation && <SelectedServiceStation id={selectedServiceStation.id} />}
      </MapModal>
      <div className={clsx(mapStyles.map, isBalloonOpen && s.balloonOpen, 'map')}>
        <Map
          width={'100%'}
          height={'100%'}
          instanceRef={mapRef}
          state={{
            zoom: zoom,
            center: center,
            bounds: center ? undefined : bounds || DefaultCity.bounds,
            behaviors: ['drag', 'dblClickZoom'],
          }}
          options={{
            suppressMapOpenBlock: true,
            // @ts-ignore
            balloonPanelMaxMapArea: isMatch ? 'Infinity' : '0',
          }}
          modules={['templateLayoutFactory', 'layout.ImageWithContent', 'util.bounds']}
          onLoad={setYmaps}
        >
          {ymaps && selectedServiceStation && center && (
            <Placemark
              modules={['geoObject.addon.balloon']}
              key={selectedServiceStation.id}
              defaultGeometry={[
                selectedServiceStation.fullAddress.coordinates.latitude,
                selectedServiceStation.fullAddress.coordinates.longitude,
              ]}
              instanceRef={ref => {
                if (!ref || isBalloonOpen || !isBalloonShow) return;

                ref.balloon.open();
                setIsBalloonOpen(true);
              }}
              onClick={() => {
                setIsShowSelectedServiceStation(prevValue => !prevValue);
              }}
              options={{
                ...defaultOptions,
                balloonOffset: [0, -16],
                openBalloonOnClick: false,
                hideIconOnBalloonOpen: false,
              }}
              properties={{
                balloonContent: `<div id=${balloonId} class="balloon"/>`,
              }}
            />
          )}
          {ymaps && (
            <>
              <Clusterer
                options={{
                  clusterIcons: [
                    {
                      size: [40, 40],
                      offset: [-20, -40],
                    },
                  ],
                  clusterIconContentLayout: ymaps?.templateLayoutFactory.createClass(
                    `<div class='${mapStyles.cluster}'>{{ properties.geoObjects.length }}</div>`
                  ),
                }}
              >
                {serviceStationsBySlugs
                  .filter(it => it.id !== selectedServiceStation?.id)
                  .map(it => (
                    <Placemark
                      modules={['geoObject.addon.balloon']}
                      key={it.id}
                      defaultGeometry={[
                        it.fullAddress.coordinates.latitude,
                        it.fullAddress.coordinates.longitude,
                      ]}
                      options={defaultOptions}
                      onClick={() => {
                        setIsShowSelectedServiceStation(prevValue => !prevValue);
                        setSelectedServiceStation(it);
                      }}
                    />
                  ))}
              </Clusterer>
              <ZoomControl
                options={{
                  position: {
                    right: 0,
                    top: '50%',
                  },
                  // @ts-ignore
                  layout: ymaps.templateLayoutFactory.createClass(`<div id="${controlsId}"/>`),
                }}
              />
            </>
          )}
        </Map>
        {selectedServiceStation &&
          balloonElement &&
          createPortal(
            <Balloon
              selectedServiceStation={selectedServiceStation}
              onClick={value => {
                setSelectedServiceStation(value);
                setIsShowSelectedServiceStation(prevValue => !prevValue);
              }}
            />,
            balloonElement
          )}
        {controlsElement &&
          createPortal(
            <MapControls zoom={zoom} setZoom={setZoom} setCenter={setCenter} />,
            controlsElement
          )}
      </div>
    </div>
  );
};
