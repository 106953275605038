import { MapComponent } from './Map';
import { Search } from '../../components/Search/Search';
import { getServiceStationAddress } from '../../../utils/getServiceStationAddress';
import { useEffect, useRef, useState } from 'react';
import classes from './appointment.module.css';
import {
  ServiceStationsControllerGetServiceStationsTypesEnum,
  ServiceStationWithDetailWorkingScheduleDto,
} from '../../../api/generated/caready';
import { ServiceStationWorkStatus } from '../../components/ServiceStationWorkStatus/ServiceStationWorkStatus';
import { useFilteredServiceStations } from '../../../hooks/useFilteredServiceStations';
import clsx from 'clsx';

export const Step3 = ({
  services,
  setSelectedServiceStationId,
  selectedServiceStation,
}: {
  selectedServiceStation?: ServiceStationWithDetailWorkingScheduleDto;
  services: string[];
  setSelectedServiceStationId: (id: string) => void;
}) => {
  const [address, setAddress] = useState('');
  const [isList, setIsList] = useState(false);
  const [selectedStationIdOnMap, setSelectedStationIdOnMap] = useState('');

  const { current: types } = useRef<ServiceStationsControllerGetServiceStationsTypesEnum[]>([
    'service-station',
    'electric-charger',
  ]);

  const serviceStations = useFilteredServiceStations({
    types,
    services,
    address,
  });

  useEffect(() => {
    if (!!selectedStationIdOnMap && !!serviceStations.length) {
      setSelectedServiceStationId(selectedStationIdOnMap);
    }
  }, [selectedStationIdOnMap]);

  return (
    <>
      <div className={classes.controls}>
        <Search clear={() => setAddress('')} isFilled={!!address}>
          <input placeholder="Поиск" value={address} onChange={e => setAddress(e.target.value)} />
        </Search>
        <button
          className={clsx('btn', 'tab', isList && 'secondary black')}
          type="button"
          onClick={() => setIsList(false)}
        >
          Карта
        </button>
        <button
          className={`btn tab ${!isList ? 'secondary black' : ''}`}
          type="button"
          onClick={() => setIsList(true)}
        >
          Список
        </button>
      </div>
      {isList ? (
        <>
          {!serviceStations.length ? (
            <div className={classes.notFound}>
              <span>Ничего не найдено</span>
            </div>
          ) : (
            <ul className={classes.list}>
              {serviceStations.map((serviceStation, idx) => (
                <li key={idx}>
                  <h6 className="h6">{getServiceStationAddress(serviceStation)}</h6>
                  <ServiceStationWorkStatus serviceStation={serviceStation} className={'p3'} />
                  <button
                    type="button"
                    className="btn"
                    onClick={() => setSelectedServiceStationId(serviceStation.id)}
                  >
                    Выбрать
                  </button>
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <div className={classes.map}>
          <MapComponent
            serviceStations={serviceStations}
            selectedServiceStation={selectedServiceStation}
            setSelect={setSelectedStationIdOnMap}
          />
        </div>
      )}
    </>
  );
};
