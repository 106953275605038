import React, { memo, useEffect, useRef, useState } from 'react';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { SkeletonGroup } from 'ui/base/Skeleton/SkeletonGroup';
import { Clusterer, Map, Placemark } from '@pbe/react-yandex-maps';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import Pin from '../../../../assets/img/map/pin.svg';
import SelectedPin from '../../../../assets/img/map/selected-pin.svg';
import { DefaultCity, useGeo } from '../../../../context/GeoProvider';
import { useMap } from '../context/MapProvider';
import { MapSearch } from './components/mapSearch/MapSearch';
import { MapSkeleton } from '../components/mapSkeleton/MapSkeleton';
import { Skeleton } from '../../../base/Skeleton/Skeleton';
import { MapControls } from '../components/mapControls/MapControls';
import { SelectedServiceStation } from '../components/selectedServiceStation/SelectedServiceStation';
import mapStyles from '../map.module.scss';
import s from './DesktopMap.module.scss';

// todo: CAREADY-258
// const loadingOptionsForIntersectionObserver: IntersectionObserverInit = {
//   rootMargin: '50px',
// };

export const DesktopMap = memo(function DesktopMap() {
  const { bounds, serviceStation } = useGeo();
  const {
    serviceStations,
    serviceStationsBySlugs,
    selectedServiceStation,
    setSelectedServiceStation,
  } = useMap();

  const [center, setCenter] = useState<number[] | undefined>();

  const mapRef = useRef<any>(null);
  const searchRef = useRef<HTMLDivElement | null>(null);

  const [ymaps, setYmaps] = useState<YMapsApi | undefined>();
  const [zoom, setZoom] = useState<number>(9);

  const containerRef = useRef<HTMLDivElement>(null);
  // todo: CAREADY-258
  // const isIntersecting = useOnceIntersecting(containerRef, loadingOptionsForIntersectionObserver);

  useEffect(() => {
    if (!serviceStation) {
      setCenter(undefined);
      setZoom(9);
    }

    setSelectedServiceStation(serviceStation);
  }, [serviceStation]);

  useEffect(() => {
    if (!selectedServiceStation) {
      return;
    }

    const { latitude, longitude } = selectedServiceStation.fullAddress.coordinates;

    setZoom(12);

    setCenter([latitude, longitude]);
  }, [selectedServiceStation]);

  // центрирование выбранной точки относительно области без MapSearch
  useEffect(() => {
    if (!mapRef.current || !searchRef.current || !containerRef.current || !center) return;

    const modalWidth = searchRef.current.offsetWidth || 0;

    const leftOffset =
      (searchRef.current.getBoundingClientRect().left || 0) -
      (containerRef.current.getBoundingClientRect().left || 0);

    const offset = leftOffset + modalWidth;

    const globalCenter = mapRef.current.getGlobalPixelCenter();

    if (!globalCenter?.length) return;

    const newGlobalCenter = [globalCenter[0] - offset / 2, globalCenter[1]];

    const geoCenter = mapRef.current.options
      .get('projection')
      .fromGlobalPixels(newGlobalCenter, mapRef.current.getZoom());

    mapRef.current?.setCenter(geoCenter);
  }, [center, ymaps]);

  const isSelectedServiceInList = serviceStations.find(it => it.id === selectedServiceStation?.id);

  return (
    <div className={mapStyles.wrapper} ref={containerRef}>
      <div className={s.searchWrapper} ref={searchRef}>
        {selectedServiceStation && isSelectedServiceInList ? (
          <>
            <button className={s.back} onClick={() => setSelectedServiceStation(null)}>
              <ArrowLeft />
              Вернуться к списку
            </button>
            <SelectedServiceStation id={selectedServiceStation.id} />
          </>
        ) : (
          <MapSearch />
        )}
      </div>
      <div className={mapStyles.map}>
        <MapControls zoom={zoom} setZoom={setZoom} setCenter={setCenter} />
        {/*todo: CAREADY-258, вернуть isLoading={isIntersecting}, если карту снова поместим вниз */}
        <SkeletonGroup isLoading={false} skeleton={MapSkeleton}>
          <>
            <Skeleton
              width={'100%'}
              height={'100%'}
              borderRadius={0}
              className={mapStyles.fakeMap}
            />
            <Map
              instanceRef={mapRef}
              width={'100%'}
              height={'100%'}
              state={{
                zoom: zoom,
                center: center,
                bounds: center ? undefined : bounds || DefaultCity.bounds,
                behaviors: ['drag', 'dblClickZoom'],
              }}
              options={{ suppressMapOpenBlock: true }}
              modules={['templateLayoutFactory', 'layout.ImageWithContent', 'util.bounds']}
              onLoad={setYmaps}
            >
              {ymaps && (
                <Clusterer
                  options={{
                    clusterIcons: [
                      {
                        size: [40, 40],
                        offset: [-20, -40],
                      },
                    ],
                    clusterIconContentLayout: ymaps?.templateLayoutFactory.createClass(
                      `<div class='${mapStyles.cluster}'>{{ properties.geoObjects.length }}</div>`
                    ),
                  }}
                >
                  {serviceStationsBySlugs.map(it => (
                    <Placemark
                      modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                      key={it.id}
                      defaultGeometry={[
                        it.fullAddress.coordinates.latitude,
                        it.fullAddress.coordinates.longitude,
                      ]}
                      options={{
                        iconLayout: 'default#imageWithContent',
                        iconImageSize: selectedServiceStation?.id === it.id ? [64, 74] : [32, 32],
                        iconImageHref: selectedServiceStation?.id === it.id ? SelectedPin : Pin,
                        iconImageOffset:
                          selectedServiceStation?.id === it.id ? [-32, -74] : [-16, -32],
                      }}
                      onClick={() => {
                        setSelectedServiceStation(it.id === selectedServiceStation?.id ? null : it);
                      }}
                    />
                  ))}
                </Clusterer>
              )}
            </Map>
          </>
        </SkeletonGroup>
      </div>
    </div>
  );
});
