import 'normalize.css';

// variables
import './styles/ui/colors.css';
import './styles/ui/corner-radius.css';
import './styles/ui/fonts.css';
import './styles/ui/shadow.css';
import './styles/ui/spacing.css';

// global
import 'swiper/css';
import 'swiper/css/effect-fade';

import './styles/container.css';
import './styles/globals.css';
import './styles/gsap.css';
import './styles/reset.css';
import './styles/slider.css';
import './styles/swiper.css';
import './styles/types.css';
import './styles/ui/button.css';
import './styles/ui/calendar.css';
import './styles/ui/map.css';
import './styles/ui/modal.css';
import './styles/ui/popover.css';
import './styles/ui/tags.css';
import './styles/ui/ymaps-popover.scss';

// prototypes
import { patchDate } from './prototypes/datePrototype';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './router/Router';

patchDate();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);
