import { CardNew } from '../../ui/components/CardNew';
import { CoreHead } from '../../ui/core';
import { HeroSection } from '../../ui/components/HeroSection/HeroSection';
import { SectionHeader } from '../../ui/base/SectionHeader';
import { useNews } from '../../hooks/useNews';
import s from './HomePage.module.scss';
import { Banner } from '../../ui/components/Banner/Banner';
import { usePromotions } from '../../hooks/usePromotions';
import { ServicesSection } from './sections/Services/ServicesSection';
import { MapComponent } from '../../ui/components/Map/Map';
import { MapProvider } from '../../ui/components/Map/context/MapProvider';
import { QuickAppointment } from '../../ui/modal/QuickAppointment/QuickAppointment';
import { useState } from 'react';
import { MobileAppSection } from '../../ui/section/MobileAppSection/MobileAppSection';

export const HomePage = function HomePage() {
  const promotions = usePromotions(1, 6);
  const { news } = useNews(1, 4);

  const [isQuickAppointmentModalShow, setIsQuickAppointmentModalShow] = useState<boolean>(false);

  return (
    <>
      <CoreHead
        title={'Комплексные услуги по ремонту автомобилей'}
        description={
          'Федеральная сеть мультибрендовых автосервисов с многофункциональными станциями обслуживания автомобилей и акцентом на высоком качестве услуг и удобном расположении'
        }
      />
      <div className={s.firstBlocks}>
        <HeroSection
          title={
            <>
              <p className={`p1-20 ${s.subtitle}`}>{'Федеральная сеть автосервисов'}</p>
              <h1 className={`h2-52 ${s.title}`}>{`Ремонт и обслуживание
                автомобилей всех марок`}</h1>
            </>
          }
          footer={
            <button
              className={`${s.headerButton} btn`}
              onClick={() => setIsQuickAppointmentModalShow(true)}
            >
              Быстрая запись на сервис
            </button>
          }
          imgPath={'home/hero/img.webp'}
          className={s.heroSection}
        />
        <section className={`section ${s.mapSection}`}>
          <MapProvider>
            <MapComponent />
          </MapProvider>
        </section>
      </div>
      <ServicesSection />
      <MobileAppSection />
      {!!promotions.length && (
        <section className={`section ${s.withHeader}`}>
          <SectionHeader title="Программа «Продленной гарантии»" link="/promo/" />
          <div className={`${s.grid} ${s.promo} ${s[`promo-${promotions.length}`]}`}>
            {promotions.map(banner => (
              <Banner key={banner.id} category={banner} />
            ))}
          </div>
        </section>
      )}
      <section className={`section ${s.withHeader}`}>
        <SectionHeader title="Новости" link="/news/" />
        <div className={s.grid}>
          {news.map(card => (
            <CardNew newsData={card} key={card.headline} />
          ))}
        </div>
      </section>
      <QuickAppointment
        open={isQuickAppointmentModalShow}
        onOpenChange={setIsQuickAppointmentModalShow}
      />
    </>
  );
};
