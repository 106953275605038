import { BREAD_CRUMB_MAIN } from '../../config/breadCrumbMain';
import { BreadCrumbs } from '../../ui/components/BreadCrumbs';
import { CoreHead } from '../../ui/core';
import { MapComponent } from '../../ui/components/Map/Map';
import classes from './map.module.css';
import { MapProvider } from '../../ui/components/Map/context/MapProvider';

export default function MapPage() {
  // todo: hid electric-charger
  // const { search } = useLocation();
  // const stations = new URLSearchParams(search).get('stations') as ServiceType | undefined;
  // const [serviceType, setServiceType] = useState<ServiceType>(stations ?? 'service-station');
  //
  // useEffect(() => {
  //   setServiceType(stations ?? 'service-station');
  // }, [stations]);

  return (
    <>
      <CoreHead
        title={'Карта сервисов'}
        description={
          'Найдите ближайшие автосервисы и точки обслуживания на интерактивной карте Caready. Легко и быстро получите информацию о местоположении, часах работы и услугах автосервисов рядом с вами'
        }
      />
      <section className="section">
        <BreadCrumbs
          links={[
            BREAD_CRUMB_MAIN,
            {
              name: 'Найти сервис',
            },
          ]}
        />
        <header className={classes.header}>
          <h1 className="h2">Карта сервисных станций</h1>
          {/*todo: hid electric-charger*/}
          {/*<div className={classes.tabs}>*/}
          {/*  <button*/}
          {/*    className={`btn tab ${serviceType !== 'service-station' ? 'secondary black' : ''}`}*/}
          {/*    onClick={() => setServiceType('service-station')}*/}
          {/*  >*/}
          {/*    Сервисные центры*/}
          {/*  </button>*/}
          {/*  <button*/}
          {/*    className={`btn tab ${serviceType !== 'electric-charger' ? 'secondary black' : ''}`}*/}
          {/*    onClick={() => setServiceType('electric-charger')}*/}
          {/*  >*/}
          {/*    Зарядные станции*/}
          {/*  </button>*/}
          {/*</div>*/}
        </header>
        <div className={classes.map}>
          {/*todo: hid electric-charger*/}
          {/*<Map types={[serviceType]} />*/}
          <MapProvider stationTypes={['service-station']}>
            <MapComponent />
          </MapProvider>
        </div>
      </section>
    </>
  );
}
