import { Clusterer, Map, Placemark } from '@pbe/react-yandex-maps';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { getServiceStationAddress } from '../../../utils/getServiceStationAddress';
import Pin from 'assets/img/map/pin.svg';
import React, { useEffect, useRef, useState } from 'react';
import classes from '../../../ui/components/Map/map.module.scss';
import {
  ServiceStationDto,
  ServiceStationWithDetailWorkingScheduleDto,
} from '../../../api/generated/caready';
import { getServiceStationOpeningHours } from '../../../utils/getServiceStationOpeningHours';
import { DefaultCity, useGeo } from '../../../context/GeoProvider';
import { MapControls } from '../../components/Map/components/mapControls/MapControls';

export const MapComponent = ({
  setSelect,
  serviceStations,
  selectedServiceStation,
}: {
  selectedServiceStation?: ServiceStationWithDetailWorkingScheduleDto;
  serviceStations: ServiceStationDto[];
  setSelect: (v: string) => void;
}) => {
  const mapRef = useRef<any>(null);
  const [ymaps, setYmaps] = useState<YMapsApi | undefined>();
  const [zoom, setZoom] = useState(9);

  const { bounds, serviceStation } = useGeo();
  const [center, setCenter] = useState<number[] | undefined>();

  useEffect(() => {
    if (!serviceStation && !selectedServiceStation) {
      setCenter(undefined);
      setZoom(9);
      return;
    }

    if (!serviceStation) return;

    const { latitude, longitude } = serviceStation.fullAddress.coordinates;

    setZoom(12);
    setCenter([latitude, longitude]);
  }, [serviceStation]);

  // @ts-ignore
  window.setSelect = (v: string) => setSelect(v);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setZoom(zoom, { duration: 300 });
    }
  }, [zoom]);

  return (
    <section className={classes.wrapper}>
      <MapControls zoom={zoom} setZoom={setZoom} setCenter={setCenter} />
      <Map
        instanceRef={mapRef}
        width={'100%'}
        height={'100%'}
        state={{
          center: selectedServiceStation
            ? [
                selectedServiceStation.fullAddress.coordinates.latitude,
                selectedServiceStation.fullAddress.coordinates.longitude,
              ]
            : center,
          zoom: 10,
          behaviors: ['drag', 'dblClickZoom'],
          margin: [20, 20, 20, 20],
          bounds: center ? undefined : bounds || DefaultCity.bounds,
        }}
        options={{ suppressMapOpenBlock: true }}
        modules={['templateLayoutFactory', 'layout.ImageWithContent', 'util.bounds']}
        onLoad={setYmaps}
      >
        {ymaps && (
          <Clusterer
            options={{
              clusterIcons: [
                {
                  size: [40, 40],
                  offset: [-20, -40],
                },
              ],
              clusterIconContentLayout: ymaps?.templateLayoutFactory.createClass(
                `<div class='${classes.cluster}'>{{ properties.geoObjects.length }}</div>`
              ),
            }}
          >
            {serviceStations.map(serviceStation => (
              <Placemark
                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                key={serviceStation.id}
                defaultGeometry={[
                  serviceStation.fullAddress.coordinates.latitude,
                  serviceStation.fullAddress.coordinates.longitude,
                ]}
                instanceRef={ref => {
                  selectedServiceStation?.id === serviceStation.id &&
                    ref &&
                    ref.balloon &&
                    ref.balloon.open();
                }}
                defaultOptions={{
                  iconLayout: 'default#imageWithContent',
                  iconImageSize: [32, 32],
                  iconImageHref: Pin,
                  iconImageOffset: [-16, -32],
                  // todo: можно ли это переписать?
                  balloonContentLayout: ymaps?.templateLayoutFactory.createClass(
                    `<div class="balloon">
                                      <h6 class="h6">${getServiceStationAddress(
                                        serviceStation
                                      )}</h6>
                                      <div class="map-info p3"><span class="${
                                        serviceStation.openingHours?.isOpen ? '' : 'closed'
                                      }">${
                                        serviceStation.openingHours?.isOpen ? 'Открыто' : 'Закрыто'
                                      }</span>${
                                        getServiceStationOpeningHours(serviceStation) &&
                                        `<span class='dot'></span>
                                         <span>${getServiceStationOpeningHours(serviceStation)}</span>`
                                      }${
                                        serviceStation.hasElectricCharger
                                          ? '<span class="electric-icon" />'
                                          : ''
                                      }</div>
                                      <button class="btn block" type="button" onclick="window.setSelect('${
                                        serviceStation.id
                                      }')">Выбрать</button>
                                   </div>`
                  ),
                }}
              />
            ))}
          </Clusterer>
        )}
      </Map>
    </section>
  );
};
