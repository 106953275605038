import { getServiceStationAddress } from '../../../../../../utils/getServiceStationAddress';
import { getServiceStationOpeningHours } from '../../../../../../utils/getServiceStationOpeningHours';
import { ServiceStationDto } from '../../../../../../api/generated/caready';
import { FC } from 'react';
import clsx from 'clsx';

interface OwnProps {
  selectedServiceStation: ServiceStationDto;
  onClick: (selectedServiceStation: ServiceStationDto) => void;
}

// todo: перенести стили из глобальных
// todo: использовать этот balloon в map из appointment (либо целиком карту)
export const Balloon: FC<OwnProps> = function Balloon(props) {
  return (
    <>
      <div className={'map-content'}>
        <h6 className={'h6'}>{getServiceStationAddress(props.selectedServiceStation)}</h6>
        <div className={'map-info p3'}>
          <span className={clsx(!props.selectedServiceStation.openingHours?.isOpen && 'closed')}>
            {props.selectedServiceStation.openingHours?.isOpen ? 'Открыто' : 'Закрыто'}
          </span>
          {getServiceStationOpeningHours(props.selectedServiceStation) && (
            <>
              <span className={'dot'}></span>
              <span>{getServiceStationOpeningHours(props.selectedServiceStation)}</span>
              {props.selectedServiceStation.hasElectricCharger && (
                <span className={'electric-icon'} />
              )}
            </>
          )}
        </div>
      </div>
      <button
        className={'btn block balloon-button'}
        type={'button'}
        onClick={() => props.onClick(props.selectedServiceStation)}
      >
        Выбрать
      </button>
    </>
  );
};
