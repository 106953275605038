import React, { FC } from 'react';
import { ReactComponent as Location } from 'assets/svg/location.svg';
import { ReactComponent as Minus } from 'assets/svg/minus.svg';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import s from './MapControls.module.scss';

interface OwnProps {
  zoom: number;
  setZoom: (value: number) => void;
  setCenter: (value: [number, number]) => void;
}

export const MapControls: FC<OwnProps> = function MapControls(props) {
  const setGeoPosition = () => {
    navigator.geolocation.getCurrentPosition(position => {
      props.setCenter([position.coords.latitude, position.coords.longitude]);
      props.setZoom(12);
    });
  };

  return (
    <div className={s.controls}>
      <div className={s.zoomControl}>
        <button onClick={() => props.setZoom(Math.min(props.zoom + 1, 18))}>
          <Plus />
        </button>
        <button onClick={() => props.setZoom(Math.max(props.zoom - 1, 4))}>
          <Minus />
        </button>
      </div>
      <button onClick={setGeoPosition}>
        <Location />
      </button>
    </div>
  );
};
