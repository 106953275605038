import { FC } from 'react';
import { ServiceStationsControllerGetServiceStationsTypesEnum } from '../../../../../api/generated/caready';
import { useMaintenanceServicesCategories } from '../../../../../hooks/useMaintenanceServices';
import { useMap } from '../../context/MapProvider';
import s from './FilterList.module.scss';

export const FilterList: FC = function FilterList() {
  const { data } = useMaintenanceServicesCategories();
  // todo: hid electric-charger
  // const { data: chargerTypes } = useQuery({
  //   queryKey: ['electric-charger-types'],
  //   queryFn: async () => {
  //     const { data } = await ServiceStationsApi.serviceStationsControllerGetElectricChargerTypes();
  //     return data;
  //   },
  // });

  const { stationTypes, selectedCategories, setSelectedCategories } = useMap();
  const stationType = stationTypes ? stationTypes[0] : 'service-station';

  return (
    <div className={s.wrapper}>
      <p className={`${s.title} pm1`}>
        {stationType ===
        ServiceStationsControllerGetServiceStationsTypesEnum.ServiceMinusStation ? (
          'Сервисы:'
        ) : (
          <>
            <span className={'electric-icon'} />
            Зарядка электромобилей:
          </>
        )}
      </p>
      <div className={'tags'}>
        {data?.map(service => (
          <button
            key={service.id}
            className={`btn tab medium ${
              selectedCategories.includes(service.slug) ? '' : 'black secondary'
            }`}
            onClick={() => {
              setSelectedCategories(
                selectedCategories.indexOf(service.slug) !== -1
                  ? [...selectedCategories].filter(i => i !== service.slug)
                  : [...selectedCategories, service.slug]
              );
            }}
          >
            {service.name}
          </button>
        ))}
      </div>
    </div>
  );
};
